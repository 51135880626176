import { Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';

type Props = FlexProps & {
  children: ReactNode;
};

function Container({ children, ...rest }: Props) {
  return (
    <Flex px={{ base: '16px', lg: '140px' }} {...rest}>
      {children}
    </Flex>
  );
}

export { Container };
