import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: 'neutral.10',
      },
    },
  },
  colors: {
    green: {
      10: '#B7EFC5',
      20: '#92E6A7',
      30: '#6EDE8A',
      40: '#4AD66D',
      50: '#2DC653',
      60: '#25A244',
      70: '#208B3A',
      80: '#1A7431',
      90: '#155D27',
      100: '#10451D',
    },
    neutral: {
      10: '#F8FAFC',
      20: '#F1F5F9',
      30: '#E2E8F0',
      40: '#CBD5E1',
      50: '#94A3B8',
      60: '#64748B',
      70: '#475569',
      80: '#334155',
      90: '#1E293B',
      100: '#0F172A',
    },
  },
});

export { theme };
