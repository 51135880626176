import { createIcon } from '@chakra-ui/react';

export const EnvelopeOutlineIcon = createIcon({
  displayName: 'EnvelopeOutlineIcon',
  viewBox: '0 0 33 32',
  path: (
    <>
      <path
        d='M4.5 7H28.5V24C28.5 24.2652 28.3946 24.5196 28.2071 24.7071C28.0196 24.8946 27.7652 25 27.5 25H5.5C5.23478 25 4.98043 24.8946 4.79289 24.7071C4.60536 24.5196 4.5 24.2652 4.5 24V7Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.5 7L16.5 18L4.5 7'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
