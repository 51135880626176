import { createIcon } from '@chakra-ui/react';

export const WhatsappOutlineIcon = createIcon({
  displayName: 'WhatsappOutlineIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <path
        d='M6.175 22.125C4.68611 19.613 4.16531 16.6439 4.71039 13.7752C5.25546 10.9064 6.82891 8.33525 9.13535 6.54438C11.4418 4.75352 14.3226 3.86611 17.237 4.04875C20.1513 4.2314 22.8988 5.47153 24.9636 7.53634C27.0284 9.60115 28.2686 12.3486 28.4512 15.263C28.6339 18.1774 27.7465 21.0582 25.9556 23.3646C24.1647 25.6711 21.5936 27.2445 18.7248 27.7896C15.8561 28.3347 12.887 27.8139 10.375 26.325V26.325L6.225 27.5C6.05497 27.5497 5.8747 27.5528 5.70307 27.5089C5.53145 27.465 5.37479 27.3757 5.24953 27.2504C5.12426 27.1252 5.035 26.9685 4.9911 26.7969C4.9472 26.6253 4.95027 26.445 5 26.275L6.175 22.125Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5125 23C18.1967 23.0033 16.8932 22.7466 15.677 22.2446C14.4607 21.7426 13.3556 21.0052 12.4252 20.0748C11.4948 19.1444 10.7574 18.0393 10.2554 16.823C9.75343 15.6068 9.4967 14.3033 9.5 12.9875C9.50331 12.0614 9.87352 11.1744 10.5295 10.5207C11.1856 9.86705 12.0739 9.50002 13 9.50003V9.50003C13.1528 9.49881 13.3031 9.5388 13.4351 9.61579C13.5671 9.69278 13.6759 9.80392 13.75 9.93753L15.2125 12.4875C15.2995 12.6425 15.3441 12.8177 15.3419 12.9955C15.3397 13.1732 15.2908 13.3472 15.2 13.5L14.025 15.4625C14.6268 16.8014 15.6986 17.8732 17.0375 18.475L19 17.3C19.1528 17.2093 19.3268 17.1603 19.5046 17.1581C19.6823 17.1559 19.8575 17.2006 20.0125 17.2875L22.5625 18.75C22.6961 18.8242 22.8072 18.933 22.8842 19.0649C22.9612 19.1969 23.0012 19.3472 23 19.5C22.9967 20.4251 22.6286 21.3116 21.9757 21.9669C21.3227 22.6222 20.4376 22.9934 19.5125 23V23Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
